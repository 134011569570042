import React, {  Suspense,lazy } from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import ReactGA from "react-ga4";

const isDev = false;

ReactGA.initialize('G-FBR7V40S2C', { testMode: isDev });

const HomeView = lazy(() => import("./screens/Home"));
const WelcomeView = lazy(() => import("./screens/Welcome"));
const Settings = lazy(() => import("./screens/Settings"));
const videoLibrary = lazy(() => import("./screens/VideoListComponent"));
// const Iframe = lazy(() => import("./screens/IframeComponent"));
const Slide = lazy(() => import("./screens/SlideWelcome"));

function App() {
  return (
    <BrowserRouter>
      <React.Fragment>
        <Suspense
          fallback={
            <div className="text-white text-center mt-3">Loading...</div>
          }
        >
          <Switch>
            {/* <Route path='/pdf' component={() => window.location = 'https://www.bauschhealth.com/Portals/25/Pdf/PI/arestin-pi.pdf'}/> */}
            {/* <Route path='/arestinpdf' render={() => <Iframe src="https://www.bauschhealth.com/Portals/25/Pdf/PI/arestin-pi.pdf" />} /> */}
            
            <Route exact path="/" component={HomeView} />
            <Route exact path="/Welcome" component={WelcomeView} />
            <Route exact path="/videoLibrary" component={videoLibrary} />
            <Route exact path="/settings" component={Settings} />
            <Route exact path="/test" component={Slide} />

            {/* <Route path="/" render={props => { ReactGA.pageview(props.location.pathname); return <HomeView />; }} />
            <Route path="/Welcome" render={props => { ReactGA.pageview(props.location.pathname); return <WelcomeView />; }} />
            <Route path="/videoLibrary" render={props => { ReactGA.pageview(props.location.pathname); return <videoLibrary />; }} />
            <Route path="/settings" render={props => { ReactGA.pageview(props.location.pathname); return <Settings />; }} /> */}
          </Switch>
        </Suspense>
      </React.Fragment>
    </BrowserRouter>
  );
}

export default App;
